<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
      <!---弹性布局flex布局开始-->
      <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartcheckManagementtextChecklist_flex_0">
          <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd0_0" isAlone="false">
              <a-form-model ref="formRef1687328925717" class="benben-flex-form heartcheckManagementtextChecklist_fd0_0_c0" label-align="right" layout="horizontal" :label-col="{span:4,offset:0}" :wrapper-col="{span:20,offset:0}">
                  <a-row class="benben-grid width_fullscreen heartcheckManagementtextChecklist_fd0_0_c0_c0" layout="4:4:4:4:4:4" :gutter="[8,0]">

                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <simple-select :disabled="false" placeholder="审核类型" mode="default" :options="fieldName_3515" :field-names='{"label":"name","value":"aid","children":"child"}'>

                              </simple-select>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c1_c0" :disabled="false" :max-length="-1" placeholder="账号" :allow-clear="true" size="default">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c2_c0" :disabled="false" :max-length="-1" placeholder="昵称" :allow-clear="true" size="default">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c3_c0" :disabled="false" :max-length="-1" placeholder="文字内容" :allow-clear="true" size="default">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <simple-select :disabled="false" placeholder="审核状态" mode="default" :options="fieldName_5730" :field-names='{"label":"name","value":"aid","children":"child"}'>

                              </simple-select>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <a-config-provider :locale="locale">
                                  <a-date-picker class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c5_c0" :disabled="false" :allow-clear="true" size="default" placeholder="创建时间" value-format="YYYY-MM-DD" format="YYYY-MM-DD">
                                  </a-date-picker>
                              </a-config-provider>
                          </a-form-model-item>

                      </a-col>

                  </a-row>
                  <a-row class="benben-grid width_fullscreen heartcheckManagementtextChecklist_fd0_0_c0_c1" layout="4:4:4:4:4:4" :gutter="[8,8]">

                      <a-col :span="4">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c1_c0_c0" :disabled="false" :max-length="-1" placeholder="处理人" :allow-clear="true" size="default">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4">

                      </a-col>



                      <a-col :span="4">

                      </a-col>



                      <a-col :span="4">

                      </a-col>



                      <a-col :span="4">

                      </a-col>



                      <a-col :span="4">
                          <div class="flex align-center flex-wrap align-center heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0" isAlone="false">
                              <a-button class="heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c0" :disabled="false" type="primary">搜索</a-button>

                              <a-button class="heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c1" :disabled="false">按钮</a-button>


                          </div>


                      </a-col>

                  </a-row>
              </a-form-model>

          </div>

      </div>
      <!---弹性布局flex布局结束-->

      <!---弹性布局flex布局开始-->
      <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartcheckManagementtextChecklist_flex_1">
          <div class="flex align-center heartcheckManagementtextChecklist_fd1_0" isAlone="false">
              <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c0" isAlone="false">
                  <a-button :disabled="false" type="primary">批量审核成功</a-button>


              </div>

              <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c1" isAlone="false">
                  <a-button class="heartcheckManagementtextChecklist_fd1_0_c1_c0" :disabled="false">批量审核失败</a-button>


              </div>

              <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c2" isAlone="false">
                  <a-button :disabled="false" type="primary">导出</a-button>


              </div>

              <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c3" isAlone="false">

              </div>


          </div>

      </div>
      <!---弹性布局flex布局结束-->



  </div>
</template>

<script>
  import {
      receive,
      benbenSelectDiy,
      benbenFlexNumberBox,
      scrollList,
      treeSelect,
      simpleSelect,
      multipleSelect,
      cascaderForm,
      checkboxForm,
      switchForm,
      messageReply,
      rangePicker,
      handSignature,
      mixinAddTableItem,
      editorForm,
      qiunDataCharts,
      copyText,
      mixinTableItemChange,
      in_array,
      themeColorInit,
      getUrlKey,
      clearDataPass,
      isEmpty
  } from '@/assets/diypaaspc/diy-pass-pc.js'
  import {
      provinceForm
  } from '@/assets/diypaaspc/province_area.js'
  import request from '@/utils/request'
  export default {
      name: "goods",
      components: {
          qiunDataCharts,
          benbenSelectDiy,
          //            productSelect, 
          benbenFlexNumberBox,
          scrollList,
          treeSelect,
          simpleSelect,
          messageReply,
          rangePicker,
          multipleSelect,
          provinceForm,
          editorForm,
          cascaderForm,
          switchForm,
          checkboxForm,
          //            uploadImage,
          //            uploadFile,
          //            uploadMedia,
          //            uploadSingleFile,
          handSignature
      },
      data() {
          receive(this);
          const themeColorConfig = {
              "presetApi": {
                  "getClassifyOne": "",
                  "getClassifyTow": "",
                  "getClassifyShop": ""
              },
              "themeType": 0,
              "themeConfig": [{
                  "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                  "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                  "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
              }]
          };
          return {
              themeColorConfig,
              "fieldName_5730": [{
                  "aid": "1",
                  "name": "选项1"
              }, {
                  "aid": "2",
                  "name": "选项2"
              }],
              "fieldName_3515": [{
                  "aid": "1",
                  "name": "选项1"
              }, {
                  "aid": "2",
                  "name": "选项2"
              }]
          };

      },
      computed: {

      },
      created() {

      },
      mounted() {

      },
      beforeDestroy() {

      },
      watch: {},
      methods: {
          copyText,
          mixinTableItemChange,
          in_array,
          themeColorInit,
          isEmpty,

      }
  }
</script>

<style lang="less" scoped>
  @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

  .antdVue {
      width: 100%;
      background: #F8F8F8;
      background-size: 100% auto !important;
  }

  #antdVue .heartcheckManagementtextChecklist_flex_0 {
      background: #fff;
      width: 100%;
      min-height: 100px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c1 {
      background: var(--benbenbgColor0);
      background-size: 100% auto !important;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c0 {
      margin: 0px 10px 0px 0px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c0_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c5_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c3_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c2_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c1_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0_c0 {
      background: #fff;
      width: 100%;
      min-height: 80px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd0_0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_flex_1 {
      background: #fff;
      width: 100%;
      min-height: 100px;
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0_c3 {
      width: 500%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0_c2 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0_c1_c0 {
      background: var(--benbenbgColor3);
      background-size: 100% auto !important;
      color: var(--benbenFontColor3);
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0_c1 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementtextChecklist_fd1_0 {
      width: 100%;
  }
</style>